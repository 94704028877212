
.navbarLogoFullScreen {
  width: auto;

  @apply h-6 md:h-[43px] md:block object-contain
}

.btnSubscribe {
  font-size: 18px;

  @apply hidden md:inline-flex items-center px-6 py-2 rounded-md shadow-sm text-white
}

.subscribeContainer {
  background-color: #528FCE;
  height: 50%;
  opacity: 90%;

  &:hover {
    opacity: 100%;
  }

  @apply absolute inset-y-0 right-0 hidden sm:flex gap-2 items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0
}

.subscribeContainerDisclosure {
  background-color: #528FCE;

  opacity: 90%;

  &:hover {
    opacity: 100%;
  }

  @apply right-0 flex gap-2 items-center pr-2 pl-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 sm:pl-0
}

.panelTitle {
  @apply px-3 py-2 rounded-md text-base self-center text-center
}

.panelTitleUnselected {
  color: #333333;
  border-width: 1px;
  border-color: #ffffff00;
  @apply rounded-full
}

.panelTitleSelected {
  color: #333333;
  border-width: 0px;

  @apply rounded-full
}

.logoContainer {
  @apply xl:grid xl:grid-cols-12 xl:w-full
}

.topBar {
  max-width: 100vw;
  padding-left: 20px;
  padding-right: 20px;
  @apply relative flex items-center justify-between md:justify-start h-[80px] md:h-[75px]
}

.miniMenu {
  @apply inline-flex items-center justify-center p-2 rounded-md text-black hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white xl:hidden
}

.navbarIconContainer {

  &:hover {

    .navbarIcon {
      display: none;
    }

    .navbarIconHover {
      display: block;
    }

    @apply text-gray-600 bg-transparent
  }

  @apply bg-black text-white aspect-square flex justify-center items-center w-8 rounded-full
}

.navbarIcon {
  display: block;
}

.navbarIconHover {
  display: none;
}

.languageSwitcheroo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: scale(0.85);
}

.languageSwitch {
  position: relative;
  margin: 0 5px;

  @apply hidden xl:inline-block
}

.languageSwitchMobile {
  position: relative;
  margin: 0 5px;

  @apply inline-block xl:hidden
}

.languageSwitch > span {
  position: absolute;
  top: 25%;
  pointer-events: none;
  font-family: 'Helvetica', Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
  width: 50%;
  text-align: center;
}

input.checkToggleRoundFlat:checked ~ .off {
  color: #79C292;
}

input.checkToggleRoundFlat:checked ~ .on {
  color: #fff;
}

.languageSwitch > span.on {
  left: 0;
  padding-left: 2px;
  color: #79C292;
}

.languageSwitch > span.off {
  right: 0;
  padding-right: 4px;
  color: #fff;
}

.checkToggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}

.checkToggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.checkToggleRoundFlat + label {
  padding: 2px;
  width: 97px;
  height: 35px;
  background-color: #619b75;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}

input.checkToggleRoundFlat + label:before, input.checkToggleRoundFlat + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.checkToggleRoundFlat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #79C292;

  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}

input.checkToggleRoundFlat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 48px;
  background-color: #fff;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

input.checkToggleRoundFlat:checked + label {
}

input.checkToggleRoundFlat:checked + label:after {
  margin-left: 42px;
}
