@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .square {
        aspect-ratio: 1/1;
    }
}

.slick-arrow {
    /*right: 5px!important;*/
    z-index: 100;

    @screen xs {
        display: none !important;
    }

    @screen lg {
        display: block !important;
    }
}

.slick-prev {
    margin-left: 20px;
    left: 0px!important;
}
.slick-next {
    margin-right: 20px;
    right: 0px!important;
}

.slick-arrow::before {
    color: #333333!important;
}

body {
    background-color: #c4c4c4;
}

.text-transition_inner {
    display: flex!important;

    div {
        @screen xs {
            height: 70px !important;
        }
        @screen md {
            height: 100px !important;
        }
    }

    @apply justify-center
}

.text-transition_placeholder {
    height: 500px!important
}

.indirectgrid10 {
    @apply grid-cols-10
}

.indirectgrid9 {
    @apply grid-cols-9
}

.indirectgrid8 {
    @apply grid-cols-8
}

.indirectgrid7 {
    @apply grid-cols-7
}

.indirectgrid6 {
    @apply grid-cols-6
}

.indirectgrid5 {
    @apply grid-cols-5
}

.indirectgrid4 {
    @apply grid-cols-4
}

.indirectgrid3 {
    @apply grid-cols-3
}

.indirectgrid2 {
    @apply grid-cols-2
}

.indirectgrid1 {
    @apply grid-cols-1
}

.indirectgridcol10 {
    @apply col-span-10
}

.indirectgridcol9 {
    @apply col-span-9
}

.indirectgridcol8 {
    @apply col-span-8
}

.indirectgridcol7 {
    @apply col-span-7
}

.indirectgridcol6 {
    @apply col-span-6
}

.indirectgridcol5 {
    @apply col-span-5
}

.indirectgridcol4 {
    @apply col-span-4
}

.indirectgridcol3 {
    @apply col-span-3
}

.indirectgridcol2 {
    @apply col-span-2
}

.indirectgridcol1 {
    @apply col-span-1
}