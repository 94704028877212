.pickerContainer {
  padding-bottom: 1rem;
  @apply shadow-inner
}

.flagContainer {
  left: auto !important;
}

.countrypickerInput {
  color:#333333;
  @apply bg-gray-100 flex-2 py-2 border-transparent !important;
}
.countrypickerArrow {
  @apply ml-2 self-center bg-black text-white rounded-full p-2
}
.countrypickerArrow:hover {
  @apply bg-gray-600
}

.iti {
  @apply bg-gray-100 rounded-2xl py-2 pl-4 pr-2 w-full flex border-transparent !important;
}

.iti__flag-container {
  /*padding-left: 16px;*/
  @apply rounded-2xl bg-gray-100 flex-1 left-auto !important;
}

.iti__country-name {
  color:#333333!important;
}
.iti__dial-code {
  @apply text-gray-800 !important
}

.iti__selected-flag {
@apply bg-transparent !important;
}

.iti__selected-dial-code {
  color:#333333!important;
}