
.underlineEffect {
  display: inline;
  position: relative;
  overflow: hidden;
}

.underlineEffect:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: -5px;
  background: #000;
  height: 4px;
  transition-property: left right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.underlineEffect:hover:after,
.underlineEffect:focus:after,
.underlineEffect:active:after {
  right: 0;
}

.footerBody {
  background-color: #333333;
  @apply py-8 flex flex-col md:flex-row md:justify-center
}

.disclaimerBody {
  background-color: #333333;
  font-size: 12px;
  font-weight: 300;
  @apply py-8 flex flex-row text-white justify-center
}

.planContainer {
  min-height: 320px;
  @apply flex self-center flex-col md:flex-row sm:w-11/12 lg:w-5/6 mt-12 gap-3
}

.footerIconHover {
  width: 75% !important;
  height: 75% !important;

  @screen md {
    width: 70% !important;
    height: 70% !important;
  }

  @screen lg {
    width: 70% !important;
    height: 70% !important;
  }

  @apply z-10
}

.footerLink {
  &:hover {
    @apply text-gray-500
  }
}

.footerIconContainer {
  &:hover {
    // background-color: #63A579; 
    //.footerIcon {
    //  display: none;
    //}

    .footerIconHover {
      display: block;
    } 
    
    rect {
      fill: #63A579 !important;
    }

    @apply text-gray-600
  } 

  @apply text-white aspect-square flex justify-center items-center z-10 
}

.footerIconHover {
  display: none;
  width: 40px !important;
  height: 40px !important;

  @apply z-20
}

.plan {
  &:hover svg {
    @apply bg-blue-500 text-white
  }

  @apply flex justify-between flex-1 flex-col rounded-2xl bg-white p-4
}

.planBody {
  .planName {
    color: #333333;
    @apply text-2xl font-bold my-1 md:my-2
  }

  .planPrice {
    color: #333333;
    @apply text-lg my-1 font-medium
  }

  .planDescription {
    @apply text-gray-500 mt-2 text-sm pb-8
  }
}

.planFooterArrow {
  .arrow {
    svg {
      @apply h-8 w-8 rounded-full p-1 stroke-2
    }

    @apply text-gray-500 text-xs h-fit flex justify-end px-2
  }
}

.tryModalBody {
  min-width: 200px;
  overflow: hidden;

  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  @screen md {
    top: unset;
    left: unset;
    transform: unset;
  }

  @apply inline-block relative align-bottom rounded-lg bg-white text-left shadow-2xl transition-all z-50 py-4 sm:align-middle sm:max-w-lg md:max-w-3xl sm:w-full
}

.tryModalClose {

  color: #838383;
  transition-duration: 0.5s;
  transition-property: transform;

  &:hover {
    color: #333333;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }

  @apply h-5 relative ml-auto transform-none
}

.subscribeModalContent {
  @apply bg-white justify-start md:p-4 flex flex-col
}

.cookiesModalContent {
  @apply bg-white justify-start p-4 flex flex-col rounded-2xl md:max-w-lg max-h-[75vh]
}

.cookiesHolder {

  &::-webkit-scrollbar {
    display: none;
  }

  @apply pt-8
}

.footerLinksContainer {
  @apply hidden lg:flex md:flex-wrap
}

.linkText {
  color: #fff;

  &:hover {
    color: #63A579;
  }

  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;

  @apply hover:underline hover:text-white pb-2
}

.headerText {
  color: #fff;

  &:hover {
    color: #63A579;
  }

  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0.5rem;
  white-space: nowrap;

  @apply hover:underline hover:text-white pb-2
}

.footerLinksColumn {
  @apply flex flex-col
}

.footerLegalContainer {
  @apply px-16 py-8 flex justify-between flex-col md:flex-row
}

.footerLegalCountryContainer {
  @apply flex justify-center md:justify-start py-4
}

.footerLegalFlag {
  @apply mx-2 rounded-full overflow-hidden h-8 w-12 self-center
}

.footerLegalCountry {
  &:hover {
    @apply text-white
  }

  @apply text-gray-300 self-center
}

.footerLegalLinks {
  a:hover {
    @apply text-white
  }

  @apply px-4 py-4 flex flex-wrap text-xs justify-center md:justify-start
}

.bottomText {
  @apply text-gray-500 self-start my-4 mx-8 text-xs
}
.inputBorder fieldset{
  border-radius: 12px !important;
}

.collapseBlack{
  @apply text-black text-xl font-medium flex
}
.collapseWhite{
  @apply text-white text-xl font-medium flex
}
